import {useCallback, useState, useMemo, useEffect} from 'react'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'

import {
  StyledBirthdayFallbackWrapper,
  StyledBirthdayTrackerWrapper,
} from './BirthdayTracker.styles'
import Introduction from './Steps/Introduction'
import KidsDataForm from './Steps/KidsDataForm'
import ThankYou from './Steps/ThankYou'
import CloseIcon from '../NavbarV2/Hamburger/CloseIcon'
import {IBirthdayTrackerScreenProps} from './BirthdayTracker.interface'

const STEP_MAPPER = {
  introduction: Introduction,
  kidsDataForm: KidsDataForm,
  thankYou: ThankYou,
}

export type StepType = keyof typeof STEP_MAPPER

function BirthdayTrackerScreens(props: IBirthdayTrackerScreenProps) {
  const {state, actions} = props || {}
  const {
    steps = {},
    closeIconColor = '',
    fallBackData,
    showFallBack = true,
  } = state ?? {}
  const {handleCloseClick = noop, onBirthdayTrackerSeen = noop} = actions ?? {}

  const {image = '', width = '', height = '', ctaLink = ''} = fallBackData ?? {}

  const [step, setStep] = useState<StepType>('introduction')

  const handleStepChange = useCallback((step: StepType) => {
    setStep(step)
  }, [])

  const currentStep = useMemo(() => {
    const Component = STEP_MAPPER[step]
    const data = steps[step]
    return <Component state={data} actions={{...actions, handleStepChange}} />
  }, [actions, handleStepChange, step, steps])

  useEffect(() => {
    onBirthdayTrackerSeen()
  }, [onBirthdayTrackerSeen])

  return (
    <>
      {showFallBack && !isEmpty(fallBackData) ? (
        <StyledBirthdayFallbackWrapper>
          <a href={ctaLink}>
            <img
              src={image}
              width={width}
              height={height}
              className="birthday-tracker-fall-back-image"
            />
          </a>
        </StyledBirthdayFallbackWrapper>
      ) : (
        <StyledBirthdayTrackerWrapper
          data-testid="birthday-tracker-screens"
          className="birthday-tracker-wrapper"
        >
          <span
            className="birthday-tracker-close-icon"
            onClick={handleCloseClick}
          >
            <CloseIcon height={14} width={14} fontColor={closeIconColor} />
          </span>
          <div className="birthday-tracker-data-container">{currentStep}</div>
        </StyledBirthdayTrackerWrapper>
      )}
    </>
  )
}

export default BirthdayTrackerScreens
