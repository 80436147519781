import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import Responsive from '../../HOC/Responsive'
import BirthdayTrackerScreens from './BirthdayTrackerScreens'
import {IBirthdayTrackerProps} from './BirthdayTracker.interface'

const BirthdayTracker = (props: IBirthdayTrackerProps) => {
  const {state, isMobile = false, actions} = props || {}
  const {showBirthdayTracker = false, steps = {}} = state ?? {}
  const {handleCloseClick = noop, saveKidsData = noop} = actions ?? {}

  const showFallBack = !showBirthdayTracker

  if (!isMobile || isEmpty(steps)) {
    return null
  }

  return (
    <>
      <BirthdayTrackerScreens
        state={{...state, showFallBack}}
        actions={{...actions, handleCloseClick, saveKidsData}}
      />
    </>
  )
}

export default Responsive(BirthdayTracker)
