import styled from 'styled-components'

export const StyledBirthdayFallbackWrapper = styled.div`
  border-radius: 12px;
  margin: 20px 0;
  .birthday-tracker-fall-back-image {
    object-fit: cover;
    border-radius: 12px;
    width: 100%;
    height: 100%;
  }
`

export const StyledBirthdayTrackerWrapper = styled.div`
  position: relative;
  border-radius: 12px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  .birthday-tracker-close-icon {
    cursor: pointer;
    display: flex;
    justify-content: end;
    padding: 10px 10px 0px 10px;
  }
  .birthday-tracker-data-container {
    padding: 0px 16px 16px 16px;
  }
  .birthday-tracker-introduction-title {
    color: var(--category-primary-color);
    font-size: 16px;
    font-weight: var(--brand-font-weight-semiBold);
    line-height: 130%;
    padding-bottom: 8px;
  }
  .birthday-tracker-introduction-data-container {
    display: flex;
    justify-content: space-between;
  }
  .birthday-tracker-introduction-description-container {
    display: flex;
    flex-flow: wrap;
    align-items: end;
  }
  .birthday-tracker-introduction-description {
    color: var(--brand-secondary-text-color);
    font-size: 12px;
    font-weight: var(--brand-font-weight-normal);
    line-height: 130%;
    padding-right: 14px;
    padding-bottom: 11px;
  }
  .birthday-tracker-introduction-button {
    height: 30px;
    background: var(--category-primary-color);
    color: var(--brand-primary-white-color);
    letter-spacing: 0em;
    padding: 12px 16px 12px 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: none;
  }
  .birthday-tracker-introduction-image {
    width: 120px;
    height: 92.28px;
  }
  .birthday-tracker-thank-you-title {
    color: var(--category-primary-color);
    font-size: 16px;
    font-weight: var(--brand-font-weight-semiBold);
    line-height: 21px;
    letter-spacing: 0em;
    margin-bottom: 5px;
  }
  .birthday-tracker-thank-you-description-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  .birthday-tracker-thank-you-description {
    font-size: 12px;
    font-weight: var(--brand-font-weight-normal);
    line-height: 16px;
    letter-spacing: 0em;
    color: var(--brand-secondary-text-color);
    margin-top: 10px;
  }
  .birthday-tracker-thank-you-image {
    width: 102px;
    height: 100px;
  }
`

export const StyledKidsDataFormWrapper = styled.div`
  .birthday-tracker-kids-data-title {
    font-size: 14px;
    font-weight: var(--brand-font-weight-semiBold);
    line-height: 150%;
    margin-bottom: 20px;
  }
  .birthday-tracker-kids-data-gender-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 20px;
  }
  .birthday-tracker-kids-data-individual-gender {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .birthday-tracker-kids-data-individual-radio {
    width: 20px;
    height: 20px;
    accent-color: rgba(47, 129, 92, 1);
  }

  .birthday-tracker-kids-data-individual-image {
    width: 40px;
    height: 40px;
  }
  .birthday-tracker-kids-data-individual-label {
    font-size: 14px;
    font-weight: var(--brand-font-weight-normal);
    line-height: 21px;
    letter-spacing: 0em;
    color: var(--brand-secondary-text-color);
  }
  .birthday-tracker-kids-data-name {
    width: 100%;
    padding: 10px 16px;
    border-radius: 6px;
    border: 1px solid var(--brand-primary-black-light-color-o2);
    font-size: 14px;
    font-weight: var(--brand-font-weight-normal);
    line-height: 21px;
    letter-spacing: 0em;
    margin-bottom: 20px;
  }
  .birthday-tracker-kids-data-date {
    height: 100%;
    width: 100%;
    padding: 10px 16px;
    border-radius: 6px;
    border: 1px solid var(--brand-primary-black-light-color-o2);
    font-size: 14px;
    font-weight: var(--brand-font-weight-normal);
    line-height: 21px;
    letter-spacing: 0em;
    margin-bottom: 20px;
    position: relative;
    box-sizing: border-box;
    outline: 0;
    display: flex;
    text-align: left;
    justify-content: start;
    position: relative;
    -moz-appearance: textfield;
    -webkit-appearance: none;
    background-color: var(--brand-primary-white-color);
    &:before {
      background-color: var(--brand-primary-white-color);
      color: var(--brand-primary-black-light-color-o5);
      content: ${({dateValueAdded}) =>
        dateValueAdded ? '' : `attr(placeholder)`};
      width: 100%;
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator,
  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  input[type='date']::-webkit-datetime-edit,
  input[type='time']::-webkit-datetime-edit {
    text-align: left;
    width: 100%;
    background-color: var(--brand-primary-white-color);
  }
  .birthday-tracker-kids-data-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .birthday-tracker-kids-data-button {
    height: 38px;
    background: var(--category-primary-color);
    color: var(--brand-primary-white-color);
    font-size: 12px;
    font-weight: var(--brand-font-weight-semiBold);
    line-height: 14px;
    letter-spacing: 0em;
    padding: 12px 16px 12px 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: none;
  }

  .birthday-tracker-kids-data-button:disabled {
    background: var(--brand-primary-subtext-color);
  }
  .birthday-tracker-kids-data-button-close {
    height: 38px;
    color: var(--category-primary-color);
    background: var(--brand-primary-white-color);
    font-size: 12px;
    font-weight: var(--brand-font-weight-semiBold);
    line-height: 14px;
    letter-spacing: 0em;
    padding: 12px 16px 12px 16px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid var(--category-primary-color);
  }
`
