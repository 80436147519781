import noop from 'lodash/noop'
import {useCallback} from 'react'
import {IIntroductionProps} from '../BirthdayTracker.interface'
import Typography from '../../Typography/Typography'

function Introduction(props: IIntroductionProps) {
  const {state, actions} = props || {}
  const {
    title = '',
    description = '',
    image = '',
    buttonText = '',
  } = state ?? {}
  const {handleStepChange = noop, onBirthdayTrackerAddChildClicked = noop} =
    actions ?? {}

  const handleButtonClicked = useCallback(() => {
    onBirthdayTrackerAddChildClicked()
    handleStepChange('kidsDataForm')
  }, [handleStepChange, onBirthdayTrackerAddChildClicked])

  return (
    <div>
      <div className="birthday-tracker-introduction-title">{title}</div>
      <div className="birthday-tracker-introduction-data-container">
        <div className="birthday-tracker-introduction-description-container">
          <div className="birthday-tracker-introduction-description">
            {description}
          </div>
          <button
            className="birthday-tracker-introduction-button"
            onClick={handleButtonClicked}
          >
            <Typography text={buttonText} variant="cta-label-sm" />
          </button>
        </div>
        {image && (
          <img
            src={image}
            alt="kids"
            className="birthday-tracker-introduction-image"
          />
        )}
      </div>
    </div>
  )
}
export default Introduction
