import {useCallback, useState, useRef, useMemo} from 'react'
import noop from 'lodash/noop'

import {IGenderData, IKidsDataFormProps} from '../BirthdayTracker.interface'
import CalloutCloseIcon from '../../Callout/CalloutCloseIcon'
import {StyledKidsDataFormWrapper} from '../BirthdayTracker.styles'
import Typography from '../../Typography/Typography'

function KidsDataForm(props: IKidsDataFormProps) {
  const {state, actions} = props
  const {
    title = '',
    genderSelector = [],
    inputPlaceHolderName = '',
    inputPlaceHolderDate = '',
    buttonText = '',
    closeButtonText = '',
  } = state ?? {}

  const {
    handleStepChange = noop,
    saveKidsData = noop,
    onBirthdayTrackerCancelClicked = noop,
  } = actions ?? {}

  const defaultKidsData = {
    gender: '',
    name: '',
    date: '',
  }

  const [kidsData, setKidsData] = useState(defaultKidsData)

  const [showClose, setShowClose] = useState(false)

  const ref = useRef()

  const handleDataChange = useCallback((event: any, value: string) => {
    setKidsData((data) => ({
      ...data,
      [value]: event.target.value,
    }))
  }, [])

  const handleButtonClick = useCallback(() => {
    setShowClose(true)
    saveKidsData(kidsData)
    setKidsData(defaultKidsData)
  }, [defaultKidsData, kidsData, saveKidsData])

  const handleCloseClick = useCallback(() => {
    onBirthdayTrackerCancelClicked()
    handleStepChange('thankYou')
  }, [handleStepChange, onBirthdayTrackerCancelClicked])

  const isButtonDisabled = useMemo(() => {
    return !(kidsData.gender && kidsData.name && kidsData.date)
  }, [kidsData.date, kidsData.gender, kidsData.name])

  return (
    <StyledKidsDataFormWrapper dateValueAdded={!!kidsData.date}>
      <div className="birthday-tracker-kids-data-title">{title}</div>
      <div
        onChange={(e) => handleDataChange(e, 'gender')}
        className="birthday-tracker-kids-data-gender-container"
      >
        {genderSelector.map((item: IGenderData) => {
          const {label = '', image = '', value = ''} = item ?? {}
          return (
            <label
              className="birthday-tracker-kids-data-individual-gender"
              key={label}
            >
              <input
                type="radio"
                value={value}
                name="gender"
                className="birthday-tracker-kids-data-individual-radio"
                checked={kidsData?.gender === value}
              />
              <img
                src={image}
                alt="kid"
                className="birthday-tracker-kids-data-individual-image"
              />
              <div className="birthday-tracker-kids-data-individual-label">
                {label}
              </div>
            </label>
          )
        })}
      </div>
      <div>
        <input
          type="text"
          value={kidsData.name}
          placeholder={inputPlaceHolderName}
          className="birthday-tracker-kids-data-name"
          onChange={(e) => handleDataChange(e, 'name')}
        />
      </div>
      <div>
        <input
          type="date"
          value={kidsData.date}
          placeholder={inputPlaceHolderDate}
          className="birthday-tracker-kids-data-date"
          onChange={(e) => handleDataChange(e, 'date')}
        />
      </div>
      <div className="birthday-tracker-kids-data-button-container">
        <button
          className="birthday-tracker-kids-data-button"
          disabled={isButtonDisabled}
          onClick={handleButtonClick}
        >
          <Typography text={buttonText} variant="cta-label-sm" />
        </button>
        {showClose && (
          <button
            className="birthday-tracker-kids-data-button-close"
            onClick={handleCloseClick}
          >
            <CalloutCloseIcon color={'var(--category-primary-color)'} />
            {closeButtonText}
          </button>
        )}
      </div>
    </StyledKidsDataFormWrapper>
  )
}

export default KidsDataForm
