import {IThankYouProps} from '../BirthdayTracker.interface'

function ThankYou(props: IThankYouProps) {
  const {state} = props || {}
  const {title = '', description = '', image = ''} = state ?? {}
  return (
    <div>
      <div className="birthday-tracker-thank-you-title">{title}</div>
      <div className="birthday-tracker-thank-you-description-container">
        <div className="birthday-tracker-thank-you-description">
          {description}
        </div>
        {image && (
          <img
            src={image}
            alt="kids"
            className="birthday-tracker-thank-you-image"
            loading="lazy"
          />
        )}
      </div>
    </div>
  )
}
export default ThankYou
